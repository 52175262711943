import ComingSoon from '../../../components/comingSoon/ComingSoon';
import ContentTabs from '../../../components/contentTab/ContentTab';
import copyIcon from '../../../assets/copy-icon.svg';
import ProjectSettings from '../contents/ProjectSettings';
import React from 'react';
import { BodyTypography } from '../../../components/atoms/typography/body/BodyTypography';
import {
  Box,
  Button,
  styled,
  Typography
  } from '@mui/material';
import { DashboardBaseContent } from '../../../constants/intl/dashboard';
import { DashboardElementType } from '../../../types/dashboardElement';
import { FlexRightContainer } from '../../../components/atoms/layout/flexBox/FlexBox';
import { fontWeight } from '../../../lib/theme/typography';
import { FormattedMessage, useIntl } from 'react-intl';
import { pxToRem } from '../../../utils/getFontValue';
import { setShowNotification } from '../../../redux/slice/snackBarSlice';
import { StepsBaseProps } from '../../../types/steps';
import { UIColors } from '../../../constants/UIConstants';
import { useAppDispatch } from '../../../redux/store';
import { v4 as uuid } from 'uuid';

interface DashboardElementsProps extends StepsBaseProps {
  data: DashboardBaseContent[];
}

type Command = {
  key: string;
  message: string;
};

const StyledFlexContainer = styled(FlexRightContainer)`
  padding: 0.5rem 0;
  justify-content: space-between;
  margin-top: ${pxToRem(16)};
`;

const CommandBox = styled(Box)`
  background-color: ${UIColors.neutrals.grey10};
  border: ${pxToRem(1)} solid ${UIColors.neutrals.grey60};
  padding: ${pxToRem(16)};
  margin-bottom: ${pxToRem(16)};
`;

const StyledSubtitle = styled(BodyTypography)`
  line-height: ${pxToRem(38)};
  margin: 0.5rem 0;
`;

const StyledCommandTitle = styled(BodyTypography)`
  line-height: ${pxToRem(38)};
  margin: 0;
`;

const StyledCopyButton = styled(Button)`
  color: ${UIColors.secondary.brightBlue};
  font-weight: ${fontWeight.semiBold};
`

const CopyIcon = styled('img')`
  width: 1rem;
`;

function DashboardElements(props: DashboardElementsProps) {
  const dispatch = useAppDispatch();
  const intl = useIntl();

  const handleCopyCommand = (command: string) => {
    navigator.clipboard.writeText(command);
    dispatch(
      setShowNotification('Copied command to clipboard.')
    );
  }

  return (
    <>
      {props.data.map((e) => {
        switch (e.type) {
          case DashboardElementType.projectSettingsComponent:
            return (
              <ProjectSettings
                key={e.key}
                id={props.id}
                content={e.content as DashboardBaseContent[]}
              />
            );

          case DashboardElementType.comingSoonComponent:
            return <ComingSoon key={e.key} />;

          case DashboardElementType.tabs:
            return (
              <ContentTabs
                key={e.key}
                id={props.id}
                content={e.content as DashboardBaseContent[]}
              />
            );

          case DashboardElementType.paragraph:
            return (
              <Typography key={e.key}>
                <FormattedMessage id={e.id} defaultMessage={e.defaultMessage} />
              </Typography>
            );

          case DashboardElementType.subtitle:
            return (
              <StyledSubtitle vx={'medium_700'} key={e.key}>
                <FormattedMessage id={e.id} defaultMessage={e.defaultMessage} />
              </StyledSubtitle>
            );

          case DashboardElementType.command:
            let subtitle: DashboardBaseContent | undefined;
            let button: DashboardBaseContent | undefined;
            if (e && Array.isArray(e.content)) {
              e.content.forEach((e) => {
                if (e.type === DashboardElementType.subtitle) {
                  subtitle = e;
                }
                if (e.type === DashboardElementType.commandCopyButton) {
                  button = e;
                }
              });
            }
            const command = intl.formatMessage({
              id: e.id,
              defaultMessage: e.defaultMessage,
            });

            let commands: Command[] = []
            command.split('\n').forEach((e) => {
              commands.push({
                key: uuid(),
                message: e
              })
            });
            return (
              <React.Fragment key={e.key}>
                <StyledFlexContainer>
                  {subtitle && (
                    <StyledCommandTitle vx={'medium_700'}>
                      <FormattedMessage
                        id={subtitle.id}
                        defaultMessage={subtitle.defaultMessage}
                      />
                    </StyledCommandTitle>
                  )}

                  {button && (
                    <StyledCopyButton
                      startIcon={<CopyIcon alt="copy icon" src={copyIcon} />}
                      variant="text"
                      onClick={() => handleCopyCommand(command)}
                    >
                      <FormattedMessage
                       id={button.id}
                       defaultMessage={button.defaultMessage}
                     />
                    </StyledCopyButton>
                  )}
                </StyledFlexContainer>
                <CommandBox>
                  {commands.map((e) => <Typography key={e.key}>{e.message}</Typography>)}
                </CommandBox>
              </React.Fragment>
            );

          default:
            return null;
        }
      })}
    </>
  );
}

export default DashboardElements;
