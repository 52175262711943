import Loader from '../../components/loader/Loader';
import { isCognitoSessionActive } from '../auth/amplify';
import { Navigate, useNavigate } from 'react-router';
import { pageRoutes } from '../../constants/routes';
import { RootState, useAppDispatch } from '../../redux/store';
import { setIsUserLoggedIn } from '../../redux/slice/authSlice';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

interface IPrivateRoute {
  children: JSX.Element | null;
}

function PrivateRoute({ children }: IPrivateRoute) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const checkAuth = useCallback(async () => {
    const response = await isCognitoSessionActive();
    if (!response) {
      navigate(pageRoutes.rootPath);
    }
    dispatch(setIsUserLoggedIn(true));
  }, [dispatch, navigate]);

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  const auth = useSelector((state: RootState) => state.auth);
  if (!auth.authCompleted) {
    return <Loader />;
  }
  return auth.isUserLoggedIn ? children : <Navigate to={pageRoutes.rootPath} />;
}

export default PrivateRoute;
