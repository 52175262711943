export enum DashboardElementType {
  // c
  command = 'command',
  commandCopyButton = 'commandCopyButton',
  comingSoonComponent = 'comingSoonComponent',
  // h
  host = 'host',
  // l
  list = 'list',
  // p   
  paragraph = 'paragraph',
  projectSettingsComponent = 'projectSettingsComponent',
  // s
  spec = 'spec',
  system = 'system',
  subtitle = 'subtitle',
  selectInputDefault = 'selectInputDefault',
  // t  
  tabs = 'tabs',
  title = 'title',
}
