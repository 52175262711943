import { Check } from 'react-feather';
import { CheckIconBox } from '../../../components/atoms/steps/Step';
import { CompletionStatus } from '../../../types/steps';
import { FormattedMessage } from 'react-intl';
import { pxToRem } from '../../../utils/getFontValue';
import { styled, Typography } from '@mui/material';
import { UIColors } from '../../../constants/UIConstants';
import { updateActiveStep } from '../../../redux/slice/stepsSlice';
import { useAppDispatch } from '../../../redux/store';

export type SubStepTabLabelProps = {
  id: string;
  isActiveTab: boolean;
  index: number;
  labelId: string;
  descriptionId: string;
  completionStatus: CompletionStatus
};

const StyledDiv = styled('div')`
  background-color: ${UIColors.globalBG.grey};
  width: 100%;
  min-height: ${pxToRem(104)};

  .active {
    background-color: ${UIColors.primary.navyBlue};
  }

  .visible {
    visibility: visible;
    background-color: ${UIColors.secondary.emarald};
    width: 100%;
    height: ${pxToRem(4)};
    position: absolute;
    bottom: 0;
  }

  .invisible {
    visibility: hidden;
  }

  .description-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: ${pxToRem(15)} ${pxToRem(15)} ${pxToRem(15)} ${pxToRem(30)};

    .heading {
      text-align: left;
    }

    .description {
      text-align: left;
      position: relative;
      top: ${pxToRem(4)};
    }
  }
`;

const StyledCheckIconBox = styled(CheckIconBox)`
  position: absolute;
  top: 1.063rem;
  left: 0.313rem;
`;

function SubStepTabLabel(props: SubStepTabLabelProps) {
  const dispatch = useAppDispatch();

  let indicatorClass: string = 'invisible';
  let tabDescriptionClass: string = '';

  if (props.isActiveTab) {
    indicatorClass = 'visible';
    tabDescriptionClass = 'active';
  } else {
    indicatorClass = 'invisible';
    tabDescriptionClass = '';
  }

  function setTabAsActive(index: number) {
    dispatch(
      updateActiveStep({
        id: props.id,
        index: props.index,
      })
    );
  }

  return (
    <StyledDiv
      className={tabDescriptionClass}
      onClick={() => setTabAsActive(props.index)}
      sx={{ position: 'relative' }}
    >
      {props.completionStatus === 'completed' && (
        <StyledCheckIconBox>
          <Check size=".75rem" color="white" />
        </StyledCheckIconBox>
      )}
      <div className="description-container">
        <div className="heading">
          <Typography
            fontFamily="Lato"
            fontWeight={700}
            fontSize= "18px"
            textTransform="none"
            color="#130739"
            lineHeight="24.66px"
          >
            <FormattedMessage id={`steps.${props.labelId}`} />
          </Typography>
        </div>
        <div className="description">
          <Typography
            fontWeight={400}
            fontSize="16px"
            lineHeight="21.92px"
            textTransform="none"
            color="#26266D"
          >
            <FormattedMessage id={`steps.${props.descriptionId}`} />
          </Typography>
        </div>
      </div>
      <div className={indicatorClass}></div>
    </StyledDiv>
  );
}

export default SubStepTabLabel;
