import { Box, styled } from '@mui/material';
import { pxToRem } from '../../../utils/getFontValue';
import { UIColors } from '../../../constants/UIConstants';

export const SubStepContainer = styled(Box)`
  flex: 1;
  background-color: ${UIColors.globalBG.grey};
  min-height: ${pxToRem(100)};
  height: 100%;
`;

export const SubStepTitleBox = styled(Box)`
  background-color: ${UIColors.primary.grey};
  height: 3.438rem;
  border-bottom: ${pxToRem(4)} solid ${UIColors.secondary.emarald};
  box-shadow: 0 ${pxToRem(4)} ${pxToRem(8)} ${pxToRem(3)} rgba(0, 0, 0, 0.15),
    0 ${pxToRem(1)} ${pxToRem(3)} rgba(0, 0, 0, 0.3);
`;

export const SubStepBody = styled(Box)`
  min-height: 6.25rem;
  padding: ${pxToRem(40)};
  width: 100%;
  display: flex;
  flex-direction: column;
`;
