import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Interface for auth slice
export interface IAuth {
  isUserLoggedIn: boolean;
  authCompleted: boolean;
}
// Initial values for slice
const initState: IAuth = {
  isUserLoggedIn: false,
  authCompleted: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: initState,
  reducers: {
    setIsUserLoggedIn: (state: any, action: PayloadAction<boolean>) => {
      state.authCompleted = true;
      state.isUserLoggedIn = action.payload;
    },
  },
});
export const { setIsUserLoggedIn } = authSlice.actions;
export default authSlice.reducer;
