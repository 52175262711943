import Snackbar from '@mui/material/Snackbar';
import { UIColors } from '../../constants/UIConstants';
import { closeNotification } from '../../redux/slice/snackBarSlice';
import { useAppDispatch, useAppSelector } from '../../redux/store';

function SnackBar() {
  const dispatch = useAppDispatch();
  const snackbar = useAppSelector((state) => state.snackbar);

  const closeSnackbar = () => {
    dispatch(closeNotification());
  };

  return (
    <Snackbar
      open={snackbar.open}
      autoHideDuration={4000}
      message={snackbar.messageInfo}
      onClose={closeSnackbar}
      ContentProps={{
        sx: {
          background: UIColors.primary.navyBlue,
        },
      }}
    />
  );
}

export default SnackBar;
