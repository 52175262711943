import { CenteredFlexBox } from '../layout/flexBox/FlexBox';
import { pxToRem } from '../../../utils/getFontValue';
import { UIColors } from '../../../constants/UIConstants';
import {
  Box,
  StepConnector,
  StepLabel,
  Stepper,
  stepConnectorClasses,
  stepLabelClasses,
  styled,
} from '@mui/material';

export const StyledIconOuterBox = styled(CenteredFlexBox)<{
  state: { active?: boolean };
}>`
  color: ${UIColors.textColors.white};
  background-color: ${UIColors.globalBG.white};
  width: ${({ state }) => (state.active ? pxToRem(76) : pxToRem(72))};
  height: ${({ state }) => (state.active ? pxToRem(76) : pxToRem(72))};
  border-radius: 50%;
  border: ${({ state }) =>
    state.active
      ? `${pxToRem(3)} solid ${UIColors.secondary.emarald}`
      : `${pxToRem(2)} solid #E1E1E1`};
  box-shadow: 0 ${pxToRem(4)} ${pxToRem(14)} rgba(0, 0, 0, 0.18);
  cursor: pointer;
`;

export const StyledIconInnerBox = styled(CenteredFlexBox)<{
  state: { active?: boolean };
}>`
  color: ${UIColors.textColors.white};
  background-color: ${({ state }) =>
    state.active ? `${UIColors.secondary.brightBlue}` : `${UIColors.globalBG.white}`};
  width: ${({ state }) => (state.active ? pxToRem(60) : pxToRem(50))};
  height: ${({ state }) => (state.active ? pxToRem(60) : pxToRem(50))};
  border: ${({ state }) =>
    state.active ? '0' : `${pxToRem(7)} solid ${UIColors.primary.navyBlue}`};
  border-radius: 50%;
`;

export const StyledStepper = styled(Stepper)(({ theme }) => ({
  alignItems: 'stretch',
  '.MuiStep-root': {
    padding: '0',
  },
  '.MuiStepLabel-root': {
    alignItems: 'flex-start',
  },
  '.MuiStepLabel-iconContainer': {
    height: `${pxToRem(76)}`,
    display: 'flex',
    alignItems: 'center',
    zIndex: 2,
    marginLeft: `${pxToRem(20)}`,
  },
  '.MuiStepLabel-labelContainer': {
    textAlign: 'center',
    marginTop: `${pxToRem(-38)}`,
    zIndex: 0,
  },
  '.MuiStepLabel-label': {
    marginTop: 0,
    padding: `${pxToRem(50)} ${pxToRem(16)} ${pxToRem(20)} ${pxToRem(20)}`,
  },
  '.MuiStepLabel-label.Mui-active': {
    backgroundColor:` ${UIColors.globalBG.grey}`,
  },
}));

export const StyledLabel = styled(StepLabel)(() => ({
  height: '100%',
  [`.${stepLabelClasses.labelContainer}`]: {
    height: '100%',
  },
  [`.${stepLabelClasses.label}`]: {
    height: '100%',
    marginTop: '0 !important',
  },
}));

export const StyledConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: `${pxToRem(36)}`,
    left: `calc(-100% + ${pxToRem(90)})`,
    right: `calc(100% + ${pxToRem(-20)})`,
    zIndex: 1,
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: `${UIColors.primary.navyBlue}`,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: '#EAEAF0',
    borderTopWidth: 5,
    borderRadius: 1,
  },
}));

export const CheckIconBox = styled(Box)`
  background: ${UIColors.secondary.emarald};
  border-radius: 50%;
  height: 1.25rem;
  width: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledCheckIconBox = styled(CheckIconBox)`
  position: absolute;
  top: -1.5rem;
  left: 2.92rem;
`;
