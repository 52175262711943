import { pageRoutes } from '../../constants/routes';
import { pxToRem } from '../../utils/getFontValue';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import {
  AppBar,
  Container,
  ContainerProps,
  Toolbar,
  Typography,
} from '@mui/material';

interface StyledContainerProps extends ContainerProps {
  hasMaxWidthLimit?: boolean;
}

const StyledAppBar = styled(AppBar)`
  background-color: ${({ theme }) => theme.palette.primary.main};
`;

export const StyledContainer = styled(Container, {
  shouldForwardProp: (prop) => prop !== 'hasMaxWidthLimit',
})<StyledContainerProps>`
  @media (min-width: ${({ theme }) => theme.breakpoints.values.lg}px) {
    padding: ${({ hasMaxWidthLimit }) =>
      hasMaxWidthLimit ? `0 ${pxToRem(24)}` : `0 ${pxToRem(46)}`};
    max-width: ${({ hasMaxWidthLimit, theme }) =>
      hasMaxWidthLimit ? `${theme.breakpoints.values.lg + 30}px` : 'unset'};
  }
`;

const StyledToolbar = styled(Toolbar)`
  height: 5.438rem;
`;

const AppTitle = styled(Typography)`
  margin-right: ${(props) => props.theme.spacing(2)};
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-weight: 700;
  font-size: 1.5rem;
  white-space: nowrap;
  line-height: 140%;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const StyledImgIcon = styled('img')`
  width: 53px;
  height: 67px;
  object-fit: cover;
`;

function Header() {
  const location = useLocation();

  const [hasMaxWidthLimit, setHasMaxWidthLimit] = useState(true);

  useEffect(() => {
    if (location.pathname === pageRoutes.rootPath) {
      setHasMaxWidthLimit(true);
    } else {
      setHasMaxWidthLimit(false);
    }
  }, [location]);

  return (
    <StyledAppBar position="static">
      <StyledContainer hasMaxWidthLimit={hasMaxWidthLimit}>
        <StyledToolbar disableGutters>
          <StyledImgIcon src="/kickdrum_logo_192.png" alt="logo" />
          <AppTitle variant="h6" as="a">
            Rapid Performance Testing
          </AppTitle>
        </StyledToolbar>
      </StyledContainer>
    </StyledAppBar>
  );
}
export default Header;
