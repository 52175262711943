import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';
import authReducer from './slice/authSlice';
import stepsReducer from './slice/stepsSlice';
import snackbarReducer from './slice/snackBarSlice';

import { isLoggingEnabled } from '../config/env';
import reduxLogger from './reduxLogger';

const isProd = process.env.REACT_APP_ENV === 'prod';

const rootReducer = combineReducers({
  stepsState: stepsReducer,
  auth: authReducer,
  snackbar: snackbarReducer,
});

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: (action) => {
    // Can add filters to block certain a action types or also mask sensitive redux values before logging.
    return action;
  },
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    let middleware = getDefaultMiddleware();
    if (isLoggingEnabled) {
      middleware.concat(reduxLogger);
    }
    return middleware;
  },
  devTools: !isProd,
  enhancers: [sentryReduxEnhancer],
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
