import {
  awsRegion,
  cognitoDomain,
  cognitoRedirectSignIn,
  cognitoRedirectSignOut,
  cognitoUserPoolId,
  cognitoWebClientId,
} from './env';

const COGNITO_SCOPES = [
  'email',
  'openid',
  'profile',
  'aws.cognito.signin.user.admin',
];

const COGNITO_RESPOSE_TYPE = 'token';

export const cognitoConfig: any = {
  Auth: {
    mandatorySignIn: true,
    region: awsRegion,
    userPoolId: cognitoUserPoolId,
    userPoolWebClientId: cognitoWebClientId,
  },
  oauth: {
    domain: cognitoDomain,
    redirectSignIn: cognitoRedirectSignIn,
    redirectSignOut: cognitoRedirectSignOut,
    responseType: COGNITO_RESPOSE_TYPE,
    scope: COGNITO_SCOPES,
  },
};
