import MainSteps from './mainSteps/MainSteps';
import ProjectHeader from './common/ProjectHeader';
import StepDescription from './common/StepDescription';
import SubStepPanel from './common/SubStepPanel';
import SubSteps from './subSteps/SubSteps';
import { Box } from '@mui/material';
import { ChevronRight } from 'react-feather';
import { PrimaryButton } from '../../components/atoms/button/primaryButton/PrimaryButton';
import { proceedToNextStep } from '../../redux/slice/stepsSlice';
import { styled } from '@mui/system';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { pxToRem } from '../../utils/getFontValue';

const DashboardContainer = styled(Box)`
  padding: 0 ${pxToRem(46)};
`;

const DashboardBody = styled(Box)`
  margin-top: 1rem;
`;

const SubStepsContainer = styled(Box)`
  display: flex;
  margin-top: 1.25rem;
`;

const SubStepBody = styled(Box)`
  flex: 1;
  height: 100%;
`;

const SubStepTabContainer = styled(Box)`
  width: 13.813rem;
  margin-right: 1.25rem;
`;

const ButtonContainer = styled(Box)`
  display: flex;
  justify-content: flex-end;
  padding: 2rem 0 0 0;
`;

const NextButtonIcon = styled(ChevronRight)`
  width: ${pxToRem(18)};
  height: ${pxToRem(18)};
`

function Dashboard() {
  const state = useAppSelector((state) => state.stepsState);
  const dispatch = useAppDispatch();

  const mainSteps = state.steps;
  const activeMainStepIndex = state.activeStepIndex;
  const activeMainStep = mainSteps[activeMainStepIndex];

  const subSteps = activeMainStep.steps ?? [];
  const activeSubStepIndex = activeMainStep.activeStepIndex;
  const activeSubStep = subSteps[activeSubStepIndex];

  const isNextEnabled =
    activeSubStep?.validationStatus &&
    activeSubStep?.validationStatus === 'valid';

  const handleNextClick = () => {
    dispatch(proceedToNextStep({ activeMainStepIndex, activeSubStepIndex }));
  };

  return (
    <DashboardContainer>
      <ProjectHeader />

      <DashboardBody pb={5}>
        <MainSteps steps={mainSteps} activeStep={activeMainStepIndex} />
        <Box>
          <StepDescription id={activeMainStep.id} />
          {subSteps && Array.isArray(subSteps) && subSteps.length > 0 && (
            <SubStepsContainer>
              <SubStepTabContainer>
                <SubSteps steps={subSteps} activeStep={activeSubStepIndex} />
              </SubStepTabContainer>
              <Box flex={1}>
                <SubStepBody>
                  <SubStepPanel id={activeSubStep.id}></SubStepPanel>
                </SubStepBody>
              </Box>
            </SubStepsContainer>
          )}
        </Box>
        <ButtonContainer>
          <PrimaryButton
            variant="outlined"
            disabled={!isNextEnabled}
            onClick={handleNextClick}
            endIcon={<NextButtonIcon />}
          >
            Next
          </PrimaryButton>
        </ButtonContainer>
      </DashboardBody>
    </DashboardContainer>
  );
}

export default Dashboard;
