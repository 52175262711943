export const landingPage = {
  heading: {
    id: 'landing.header',
    default: 'Landing Page',
  },
};

export const loginPage = {
  googleSignInButton: {
    id: 'login.googleSignInButton',
    default: 'SIGN IN WITH {signInOption} ACCOUNT',
  },
  divider: {
    id: 'login.divider',
    default: 'OR',
  },
  email: {
    id: 'login.email',
    default: 'Email',
  },
  usernamePlaceholder: {
    id: 'login.usernamePlaceholder',
    default: 'Enter username',
  },
  password: {
    id: 'login.password',
    default: 'Password',
  },
  passwordPlaceholder: {
    id: 'login.passwordPlaceholder',
    default: 'Enter password',
  },
  forgotPassword: {
    id: 'login.forgotPassword',
    default: 'Forgot Password?',
  },
  loginButton: {
    id: 'login.loginButton',
    default: 'LOGIN',
  },
};
