import { Button, styled } from '@mui/material';
import { fontWeight } from '../../../lib/theme/typography';
import { pxToRem } from '../../../utils/getFontValue';

export const BaseStyledButton = styled(Button)({
  height: pxToRem(44),
  fontSize: pxToRem(18),
  fontWeight: fontWeight.bold,
  letterSpacing: '0.02em',
  textTransform: 'uppercase',
});