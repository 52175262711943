import { v4 as uuid } from 'uuid';
import { DashboardElementType } from '../../types/dashboardElement';

export type DashboardContent = {
  [key: string]: {
    [key: string]:
      | SubStepContent
      | DashboardBaseContent
      | DashboardBaseContent[];
    stepDescription: DashboardBaseContent[];
  };
};

export type SubStepContent = {
  subStepPanelTitle: DashboardBaseContent;
  subStepContent: DashboardBaseContent[];
};

// Defines the type for dashboard element
// id and defaultMessage attributes primarily used for intl message formatting 
// type attribute primarily used for identifying element 
// content attribute has child element/elements 
export type DashboardBaseContent = {
  id?: string;
  defaultMessage?: string;
  type?: DashboardElementType;
  content?: DashboardBaseContent | DashboardBaseContent[];
  key: string;
};

export const dashboardContent: DashboardContent = {
  '1': {
    stepDescription: [
      {
        id: 'steps.1.instructions.title',
        defaultMessage: 'defaultMessage',
        type: DashboardElementType.title,
        key: uuid(),
      },
      {
        id: 'steps.1.instructions.text_1',
        defaultMessage: 'defaultMessage',
        type: DashboardElementType.paragraph,
        key: uuid(),
      },
      {
        id: 'steps.1.instructions.list_1',
        defaultMessage: 'defaultMessage',
        type: DashboardElementType.list,
        key: uuid(),
      },
      {
        id: 'steps.1.instructions.list_2',
        defaultMessage: 'defaultMessage',
        type: DashboardElementType.list,
        key: uuid(),
      },
      {
        id: 'steps.1.instructions.list_3',
        defaultMessage: 'defaultMessage',
        type: DashboardElementType.list,
        key: uuid(),
      },
    ],
    a: {
      subStepPanelTitle: {
        id: 'steps.1.a.panel.title',
        defaultMessage: 'defaultMessage',
        key: uuid(),
      },
      subStepContent: [
        {
          type: DashboardElementType.projectSettingsComponent,
          key: uuid(),
          content: [
            {
              id: 'steps.1.a.panel.system',
              defaultMessage: 'System',
              type: DashboardElementType.system,
              key: uuid(),
            },
            {
              id: 'steps.1.a.panel.host',
              defaultMessage: 'Host',
              type: DashboardElementType.host,
              key: uuid(),
            },
            {
              id: 'steps.1.a.panel.spec',
              defaultMessage: 'Spec',
              type: DashboardElementType.spec,
              key: uuid(),
            },
            {
              id: 'steps.1.a.panel.selectInputDefault',
              defaultMessage: 'Select',
              type: DashboardElementType.selectInputDefault,
              key: uuid(),
            },
          ],
        },
      ],
    },
    b: {
      subStepPanelTitle: {
        id: 'steps.1.b.panel.title',
        defaultMessage: 'defaultMessage',
        key: uuid(),
      },
      subStepContent: [
        {
          id: 'steps.1.b.panel.description',
          defaultMessage: 'defaultMessage',
          type: DashboardElementType.paragraph,
          key: uuid(),
        },
        {
          type: DashboardElementType.tabs,
          key: uuid(),
          content: [
            {
              id: 'steps.1.b.panel.tabs.cli.tabHeader',
              defaultMessage: 'defaultMessage',
              key: uuid(),
              content: [
                {
                  id: 'steps.1.b.panel.tabs.cli.description',
                  defaultMessage: 'defaultMessage',
                  type: DashboardElementType.paragraph,
                  key: uuid(),
                },
                {
                  id: 'steps.1.b.panel.tabs.cli.command',
                  defaultMessage: 'defaultMessage',
                  type: DashboardElementType.command,
                  key: uuid(),
                  content: [
                    {
                      id: 'steps.1.b.panel.tabs.cli.subtitle',
                      defaultMessage: 'defaultMessage',
                      type: DashboardElementType.subtitle,
                      key: uuid(),
                    },
                    {
                      id: 'steps.misc.commandCopyButton',
                      defaultMessage: 'defaultMessage',
                      type: DashboardElementType.commandCopyButton,
                      key: uuid(),
                    },
                  ]
                },
              ],
            },
            {
              id: 'steps.1.b.panel.tabs.script.tabHeader',
              defaultMessage: 'defaultMessage',
              key: uuid(),
              content: [
                {
                  type: DashboardElementType.comingSoonComponent,
                  key: uuid(),
                },
              ],
            },
            {
              id: 'steps.1.b.panel.tabs.gui.tabHeader',
              defaultMessage: 'defaultMessage',
              key: uuid(),
              content: [
                {
                  type: DashboardElementType.comingSoonComponent,
                  key: uuid(),
                },
              ],
            },
          ],
        },
      ],
    },
    c: {
      subStepPanelTitle: {
        id: 'steps.1.c.panel.title',
        defaultMessage: 'defaultMessage',
        key: uuid(),
      },
      subStepContent: [
        {
          id: 'steps.1.c.panel.description',
          defaultMessage: 'defaultMessage',
          type: DashboardElementType.paragraph,
          key: uuid(),
        },
        {
          type: DashboardElementType.tabs,
          key: uuid(),
          content: [
            {
              id: 'steps.1.c.panel.tabs.gui.tabHeader',
              defaultMessage: 'defaultMessage',
              key: uuid(),
              content: [
                {
                  type: DashboardElementType.comingSoonComponent,
                  key: uuid(),
                },
              ],
            },
            {
              id: 'steps.1.c.panel.tabs.cli.tabHeader',
              defaultMessage: 'defaultMessage',
              key: uuid(),
              content: [
                {
                  type: DashboardElementType.comingSoonComponent,
                  key: uuid(),
                },
              ],
            },
          ],
        },
      ],
    },
  },

  '2': {
    stepDescription: [
      {
        type: DashboardElementType.title,
        id: 'steps.2.instructions.title',
        key: uuid(),
      },
      {
        type: DashboardElementType.paragraph,
        id: 'steps.2.instructions.text_1',
        key: uuid(),
      },
      {
        type: DashboardElementType.list,
        id: 'steps.2.instructions.list_1',
        key: uuid(),
      },
      {
        type: DashboardElementType.list,
        id: 'steps.2.instructions.list_2',
        key: uuid(),
      },
    ],
    a: {
      subStepPanelTitle: {
        id: 'steps.2.a.panel.title',
        defaultMessage: 'defaultMessage',
        key: uuid(),
      },
      subStepContent: [
        {
          id: 'steps.2.a.panel.description',
          defaultMessage: 'defaultMessage',
          type: DashboardElementType.paragraph,
          key: uuid(),
        },
        {
          type: DashboardElementType.tabs,
          key: uuid(),
          content: [
            {
              id: 'steps.2.a.panel.tabs.cli.tabHeader',
              defaultMessage: 'defaultMessage',
              key: uuid(),
              content: [
                {
                  id: 'steps.2.a.panel.tabs.cli.description',
                  defaultMessage: 'defaultMessage',
                  type: DashboardElementType.paragraph,
                  key: uuid(),
                },
                {
                  id: 'steps.2.a.panel.tabs.cli.command',
                  defaultMessage: 'defaultMessage',
                  type: DashboardElementType.command,
                  key: uuid(),
                  content: [
                    {
                      id: 'steps.2.a.panel.tabs.cli.subtitle',
                      defaultMessage: 'defaultMessage',
                      type: DashboardElementType.subtitle,
                      key: uuid(),
                    },
                    {
                      id: 'steps.misc.commandCopyButton',
                      defaultMessage: 'defaultMessage',
                      type: DashboardElementType.commandCopyButton,
                      key: uuid(),
                    },
                  ]
                },
              ],
            },
            {
              id: 'steps.1.b.panel.tabs.script.tabHeader',
              defaultMessage: 'defaultMessage',
              key: uuid(),
              content: [
                {
                  type: DashboardElementType.comingSoonComponent,
                  key: uuid(),
                },
              ],
            },
            {
              id: 'steps.1.b.panel.tabs.gui.tabHeader',
              defaultMessage: 'defaultMessage',
              key: uuid(),
              content: [
                {
                  type: DashboardElementType.comingSoonComponent,
                  key: uuid(),
                },
              ],
            },
          ],
        },
      ],
    },
    b: {
      subStepPanelTitle: {
        id: 'steps.2.b.panel.title',
        defaultMessage: 'defaultMessage',
        key: uuid(),
      },
      subStepContent: [
        {
          id: 'steps.2.b.panel.description',
          defaultMessage: 'defaultMessage',
          type: DashboardElementType.paragraph,
          key: uuid(),
        },
        {
          type: DashboardElementType.tabs,
          key: uuid(),
          content: [
            {
              id: 'steps.2.b.panel.tabs.gui.tabHeader',
              defaultMessage: 'defaultMessage',
              key: uuid(),
              content: [
                {
                  type: DashboardElementType.comingSoonComponent,
                  key: uuid(),
                },
              ],
            },
            {
              id: 'steps.2.b.panel.tabs.cli.tabHeader',
              defaultMessage: 'defaultMessage',
              key: uuid(),
              content: [
                {
                  type: DashboardElementType.comingSoonComponent,
                  key: uuid(),
                },
              ],
            },
          ],
        },
      ],
    },
  },
};
