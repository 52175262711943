import { UIColors } from '../../../constants/UIConstants';
import {
  FormControl,
  MenuItem,
  Select as MUISelect,
  styled,
} from '@mui/material';

type ISelectProps = {
  name: string;
  value: string;
  options: string[];
  onChange: (e: any) => void;
  em: string;
};

const StyledFormControl = styled(FormControl)`
  padding: 0;
`;

const StyledSelect = styled(MUISelect)`
  padding: 0;
  width: 15rem;
  background: ${UIColors.globalBG.white};
  text-transform: none;
  font-size: 1rem;
  .MuiSelect-select {
    padding: 0.5rem 2rem 0.5rem 1rem;
  }
`;

function Select(props: ISelectProps) {
  return (
    <StyledFormControl>
      <StyledSelect
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        displayEmpty
        inputProps={{ 'aria-label': 'Without label' }}
      >
        {props.em && (
          <MenuItem value="" disabled>
            <em>{props.em}</em>
          </MenuItem>
        )}
        {props.options.map((step) => (
          <MenuItem key={step} value={step}>
            {step}
          </MenuItem>
        ))}
      </StyledSelect>
    </StyledFormControl>
  );
}

export default Select;
