import { logLevelToInt } from '../../constants/logger';
import { LogLevelType } from '../../types/logger';
import { configLogLevel, isLoggingEnabled } from '../../config/env';

const logger = (logLevel: LogLevelType, ...args: any[]) => {
  if (!isLoggingEnabled) {
    console.log('LOGGING DISABLED');
    return;
  }
  if (logLevelToInt[logLevel] >= logLevelToInt[configLogLevel]) {
    console[logLevel](...args);
  }
};

export default logger;
