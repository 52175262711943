import { LogLevelType } from '../types/logger';

// Project Info
export const environment = process.env.REACT_APP_ENV;
export const projectName = process.env.REACT_APP_PROJECT_NAME;
export const projectVersion = process.env.npm_package_version;

// Logging
export const isLoggingEnabled =
  process.env.REACT_APP_ENV_ENABLE_LOGGING === '1';
export const configLogLevel: LogLevelType = process.env
  .REACT_APP_LOG_LEVEL as LogLevelType;

// Sentry
export const isSentryEnabled = process.env.REACT_APP_IS_SENTRY_ENABLED === '1';
export const sentryDsn = process.env.REACT_APP_SENTRY_DSN as string;

// AWS - General
export const awsRegion = process.env.REACT_APP_AWS_REGION;

// AWS - Cognito
export const cognitoUserPoolId = process.env.REACT_APP_COGNITO_USER_POOL_ID;
export const cognitoWebClientId = process.env.REACT_APP_COGNITO_WEB_CLIENT_ID;
export const cognitoDomain = process.env.REACT_APP_COGNITO_DOMAIN;
export const cognitoRedirectSignIn = process.env.REACT_APP_SIGN_IN_REDIRECT_URL;
export const cognitoRedirectSignOut =
  process.env.REACT_APP_SIGN_OUT_REDIRECT_URL;
