import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ISnackBar {
  open: boolean;
  messageInfo: string;
}
const initialState: ISnackBar = {
  open: false,
  messageInfo: '',
};

export const snackBarSlice = createSlice({
  name: 'snackBar',
  initialState: initialState,
  reducers: {
    setShowNotification: (state, action: PayloadAction<string>) => {
      state.messageInfo = action.payload;
      state.open = true;
    },
    closeNotification: (state) => {
      state.open = false;
      state.messageInfo = '';
    },
  },
});

export const { setShowNotification, closeNotification } = snackBarSlice.actions;
export default snackBarSlice.reducer;
