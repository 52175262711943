import messages_en from './en.json';
import messages_fr from './fr.json';

import { supportedLanguages } from '../config/application';
import { flattenMessages } from '../utils/flattenMessages';
import { AllMessagesType, LocaleType } from '../types/i18n';

// Get browser's language
const browserLanguage: LocaleType = window.navigator.language.substring(
  0,
  2
) as LocaleType;

//Default to english if browser's language is not supported
export const locale: LocaleType = supportedLanguages.includes(browserLanguage)
  ? browserLanguage
  : 'en';

const allMessages: AllMessagesType = {
  en: messages_en,
  fr: messages_fr,
};

export const messages = flattenMessages(allMessages[locale]);
