import { createLogger } from 'redux-logger';

// Reducer actions that we do not want to log
const actionsToAvoid: string[] = [];

const reduxLogger = createLogger({
  level: process.env.REACT_APP_LOG_LEVEL,
  diff: true,
  predicate: (getState, action) => !actionsToAvoid.includes(action.type),
  duration: true,
  timestamp: true,
});

export default reduxLogger;
