import {
  Box,
  Grid,
  styled,
  Typography
  } from '@mui/material';
import { fontWeight } from '../../../lib/theme/typography';
import { PageTitle } from '../../../components/atoms/typography/pageTitle/PageTitle';
import { UIColors } from '../../../constants/UIConstants';

const StyledGrid = styled(Grid)`
  padding: 2rem 1.6875rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  & .left {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  & .right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
`;

const Heading = styled(PageTitle)`
  font-weight: ${fontWeight.bold};
`;

const IconBox = styled(Box)`
  background-color: ${UIColors.primary.navyBlue};
  border-radius: 50%;
  width: 2.125rem;
  height: 2.125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.25rem;
`;
const SupportIcon = styled('img')`
  width: 1rem;
`;

function ProjectHeader() {
  return (
    <StyledGrid container>
      <Grid item sm={12} lg={8} className="left">
        <Heading vx={'medium'}>KICKDRUM - MYSQL PERF TEST - 01/02/2023</Heading>
      </Grid>
      <Grid item sm={12} lg={4} className="right">
        <IconBox>
          <SupportIcon alt="tech support icon" src="/icons/headmicset.svg" />
        </IconBox>
        <Typography textAlign="right">techsupport@kickdrumtech.com</Typography>
      </Grid>
    </StyledGrid>
  );
}

export default ProjectHeader;
