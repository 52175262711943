import * as Sentry from '@sentry/react';
import App from './App';
import React from 'react';
import ReactDOM from 'react-dom/client';
import sentryLogger from './utils/sentry/sentrySetup';
import store from './redux/store';
import { IntlProvider } from 'react-intl';
import { locale, messages } from './i18n/reactIntlSetup';
import { Provider as ReduxProvider } from 'react-redux';
import './config/amplifyConfig';
import './sass/index.scss';

sentryLogger.init();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <IntlProvider locale={locale} messages={messages}>
        <Sentry.ErrorBoundary>
          <App />
        </Sentry.ErrorBoundary>
      </IntlProvider>
    </ReduxProvider>
  </React.StrictMode>
);
