import ComingSoon from '../../../components/comingSoon/ComingSoon';
import { BodyTypography } from '../../../components/atoms/typography/body/BodyTypography';
import { Box, ListItem, styled } from '@mui/material';
import { dashboardContent } from '../../../constants/intl/dashboard';
import { DashboardElementType } from '../../../types/dashboardElement';
import { FormattedMessage } from 'react-intl';
import { SectionTitle } from '../../../components/atoms/typography/sectionTitle/SectionTitle';
import { StepsBaseProps } from '../../../types/steps';
import { UIColors } from '../../../constants/UIConstants';

interface StepDescriptionProps extends StepsBaseProps {}

const StyledBox = styled(Box)`
  width: 100%;
  min-height: 12.5rem;
  background-color: ${UIColors.globalBG.grey};
  padding: 1.875rem;
`;

const StyledSectionTitle = styled(SectionTitle)`
  margin-bottom: 0.625rem;
`;

const StyledText = styled(BodyTypography)`
  margin-bottom: 0.28rem;
`;

const StyledListText = styled(BodyTypography)`
  display: list-item;
`;

function StepDescription({ id }: StepDescriptionProps) {
  const data = dashboardContent[id]?.stepDescription;

  return (
    <StyledBox>
      {data && Array.isArray(data) ? (
        data.map((e, i) => {
          switch (e.type) {
            case DashboardElementType.title:
              return (
                <StyledSectionTitle key={i} vx={'medium'}>
                  <FormattedMessage id={e.id} />
                </StyledSectionTitle>
              );
            case DashboardElementType.paragraph:
              return (
                <StyledText key={i} vx={'medium_500'}>
                  <FormattedMessage id={e.id} />
                </StyledText>
              );
            case DashboardElementType.list:
              return (
                <ListItem key={i}>
                  <StyledListText vx={'medium_500'}>
                    <FormattedMessage id={e.id} />
                  </StyledListText>
                </ListItem>
              );
            default:
              return <></>;
          }
        })
      ) : (
        <ComingSoon />
      )}
    </StyledBox>
  );
}

export default StepDescription;
