import { responsiveFontSizes } from "../../utils/getFontValue";

// Based on Adobe Lato Font Weights
type FontWeightKeys =
  | 'hairline'
  | 'thin'
  | 'light'
  | 'regular'
  | 'medium'
  | 'semiBold'
  | 'bold'
  | 'heavy'
  | 'black';
  
export type FontWeight = 
  | 100 
  | 200 
  | 300 
  | 400 
  | 500 
  | 600 
  | 700 
  | 800 
  | 900;

export const fontWeight: { [key in FontWeightKeys]: FontWeight }  = {
  hairline: 100,
  thin: 200,
  light: 300,
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
  heavy: 800,
  black: 900,
};

export const typography = {
    fontFamily: [
        'Lato',
        'sans-serif'
    ].join(','),
    h1: {
        ...responsiveFontSizes({ xs: 32, md: 40, lg: 46 }),
    },
    h2: {
        ...responsiveFontSizes({ xs: 24, md: 28, lg: 32 }),
    },
    h3: {
        ...responsiveFontSizes({ xs: 18, md: 20, lg: 24 }),
    },
} as const;
