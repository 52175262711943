import { BreakPointValueKeys, breakpoints } from "../lib/theme/breakpoints";

export function pxToRem(value: number) {
  return `${value / 16}rem`;
}

type ResponsiveFontSizesOptions = {
  [key in BreakPointValueKeys]?: number;
}

interface FontSizeStyle {
  fontSize: string;
}

interface ResponsiveFontSizeStyles {
  [key: string]: FontSizeStyle;
}

export function responsiveFontSizes(options: ResponsiveFontSizesOptions) {
  const responsiveStyles: ResponsiveFontSizeStyles = {};
  Object.keys(options).forEach((key) => {
    if(options[key as unknown as BreakPointValueKeys] !== undefined) {
      const minWidth = breakpoints.values[key as unknown as BreakPointValueKeys];
      responsiveStyles[`@media (min-width:${minWidth}px)`] = {
        fontSize: pxToRem(options[key as unknown as BreakPointValueKeys] as unknown as number),
      }
    }
  })
  return responsiveStyles
}


