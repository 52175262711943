import { FormattedMessage } from 'react-intl';
import {
  SubStepContainer,
  SubStepTitleBox,
  SubStepBody,
} from '../../../components/atoms/steps/SubStep';
import { SubStepTitle } from '../../../components/atoms/typography/stepText/SubStep';
import { dashboardContent, SubStepContent } from '../../../constants/intl/dashboard';
import { StepsBaseProps } from '../../../types/steps';
import DashboardElements from './DashboardElements';

interface SubStepPanelProps extends StepsBaseProps {}

function SubStepPanel(props: SubStepPanelProps) {
  const splitIds: string[] = props.id.split('.');
  const data = dashboardContent[splitIds[0]][splitIds[1]];

  let title;
  let content;

  // Checks if data if of type object and not array or string 
  // and reads title and content value from it. 
  if (typeof data === 'object') {
    if ('subStepPanelTitle' in data) {
      title = (data as SubStepContent).subStepPanelTitle
    }

    if ('subStepContent' in data) {
      content = (data as SubStepContent).subStepContent
    }
  } 

  return (
    <SubStepContainer>
      <SubStepTitleBox>
        <SubStepTitle>
          {title && title.id && (
            <FormattedMessage
              id={title.id}
              defaultMessage={title.defaultMessage}
            />
          )}
        </SubStepTitle>
      </SubStepTitleBox>
      <SubStepBody>
         {content && 
          <DashboardElements data={content} id={props.id}></DashboardElements> 
         }
      </SubStepBody>
    </SubStepContainer>
  );
}

export default SubStepPanel;
