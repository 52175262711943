

export type BreakPointValueKeys = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export type BreakPoints = {
  values: {
    [key in BreakPointValueKeys]: number;
  }
}

export const breakpoints: BreakPoints  = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
  }
};
