import { BaseBodyTypography } from '../Base';
import { fontWeight } from '../../../../lib/theme/typography';
import { pxToRem } from '../../../../utils/getFontValue';
import { styled } from '@mui/material/styles';
import { TypographyProps } from '@mui/material/Typography/Typography';

type VX = 'large' | 'medium' | 'small' | 'medium_500' | 'medium_700';

interface IProps extends TypographyProps {
    vx: VX;
}

const getFontSize = (vx: VX): string => {
  switch (vx) {
    case 'large':
      return pxToRem(18);
    case 'medium':
    case 'medium_500':
    case 'medium_700':
      return pxToRem(16);
    case 'small':
      return pxToRem(14);
    default:
      return pxToRem(16);
  }
};

const getFontWeight = (vx: VX): number => {
  switch (vx) {
    case 'medium_700':
      return fontWeight.bold;
    case 'medium_500':
        return fontWeight.medium;
    default:
        return fontWeight.regular;
  }
};

export const BodyTypography = styled(BaseBodyTypography)<IProps>((props) => ({
  fontSize: getFontSize(props.vx),
  fontWeight: getFontWeight(props.vx),
}));