import { BaseTitleTypography } from '../Base';
import { pxToRem } from '../../../../utils/getFontValue';
import { styled } from '@mui/material/styles';
import { TypographyProps } from '@mui/material/Typography/Typography';

type VX = 'large' | 'medium';

interface IProps extends TypographyProps {
    vx: VX;
}

export const PageTitle = styled(BaseTitleTypography)<IProps>((props) => ({
  fontSize: props.vx === 'large' ? pxToRem(46) : pxToRem(30)
}));