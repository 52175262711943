import { BaseStyledButton } from '../Base';
import { ButtonProps } from '@mui/material/Button';
import { pxToRem } from '../../../../utils/getFontValue';
import { styled } from '@mui/material/styles';
import { UIColors } from '../../../../constants/UIConstants';

export const PrimaryButton = styled(BaseStyledButton)<ButtonProps>(() => ({
  color: `${UIColors.primary.navyBlue}`,
  paddingLeft: pxToRem(38),
  paddingRight: pxToRem(38),
  border: `${pxToRem(3)} solid ${UIColors.primary.navyBlue}`,

  '&:hover': {
    color: `${UIColors.textColors.white}`,
    backgroundColor: `${UIColors.primary.navyBlue}`,
    border: `${pxToRem(3)} solid ${UIColors.primary.navyBlue}`,
  },

  '&.Mui-disabled': {
    backgroundColor: `${UIColors.neutrals.grey60}`,
    color: `${UIColors.neutrals.grey80}`,
    border: `${pxToRem(3)} solid ${UIColors.neutrals.grey80}`,
  },
}));
