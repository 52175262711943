import { BaseTitleTypography } from '../../../components/atoms/typography/Base';
import { BodyTypography } from '../../../components/atoms/typography/body/BodyTypography';
import { Box, Step, styled } from '@mui/material';
import { Check } from 'react-feather';
import { CompletionStatus, IStep } from '../../../types/steps';
import { FormattedMessage } from 'react-intl';
import { pxToRem } from '../../../utils/getFontValue';
import { SubTitle } from '../../../components/atoms/typography/subTitle/SubTitle';
import { UIColors } from '../../../constants/UIConstants';
import { updateActiveStep } from '../../../redux/slice/stepsSlice';
import { useAppDispatch } from '../../../redux/store';
import {
  StyledCheckIconBox,
  StyledConnector,
  StyledIconInnerBox,
  StyledIconOuterBox,
  StyledLabel,
  StyledStepper,
} from '../../../components/atoms/steps/Step';

interface ICustomStepIconProps {
  index: number;
  isActive: boolean;
  completionStatus: CompletionStatus;
}

interface IProps {
  steps: IStep[];
  activeStep: number | undefined;
}

const StepTitle = styled(SubTitle)`
  text-align: initial;
`;

const StepDescription = styled(BodyTypography)`
  text-align: initial;
  color: ${UIColors.textColors.grey90};
  line-height: 1.55;
`;

const StepperFont = styled(BaseTitleTypography)<{
  state: { active?: boolean };
}>`
  font-size: ${({ state }) => (state.active ? pxToRem(26) : pxToRem(20))};
  color: ${({ theme, state }) =>
    state.active ? UIColors.textColors.white : theme.palette.primary.main};
`;

function StepIcon(props: ICustomStepIconProps) {
  const dispatch = useAppDispatch();
  const { index, isActive, completionStatus } = props;

  const onStepClick = () => {
    dispatch(
      updateActiveStep({
        id: JSON.stringify(index),
        index: index,
      })
    );
  };

  return (
    <>
      {completionStatus === 'completed' && (
        <StyledCheckIconBox>
          <Check size=".75rem" color="white" />
        </StyledCheckIconBox>
      )}
      <StyledIconOuterBox state={{ active: isActive }} onClick={onStepClick}>
        <StyledIconInnerBox state={{ active: isActive }}>
          <StepperFont state={{ active: isActive }}>{index + 1}</StepperFont>
        </StyledIconInnerBox>
      </StyledIconOuterBox>
    </>
  );
}

function MainSteps({ steps, activeStep }: IProps) {
  return (
    <StyledStepper
      nonLinear
      activeStep={activeStep}
      alternativeLabel
      connector={<StyledConnector />}
    >
      {steps.map((step, index) => (
        <Step key={step.labelId}>
          <StyledLabel
            StepIconComponent={() => (
              <StepIcon
                {...{
                  isActive: activeStep === index,
                  index,
                  completionStatus: step.completionStatus,
                }}
              />
            )}
          >
            <Box>
              <StepTitle vx={'medium'} variant="subtitle1">
                <FormattedMessage id={`steps.${step.labelId}`} />
              </StepTitle>
              <StepDescription vx={'small'}>
                <FormattedMessage id={`steps.${step.descriptionId}`} />
              </StepDescription>
            </Box>
          </StyledLabel>
        </Step>
      ))}
    </StyledStepper>
  );
}

export default MainSteps;
