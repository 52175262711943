import googleLogo from '../../assets/google-logo.svg';
import { FormattedMessage, useIntl } from 'react-intl';
import { googleSignIn, isCognitoSessionActive } from '../../utils/auth/amplify';
import { loginPage } from '../../constants/intlMessageIds';
import { pageRoutes } from '../../constants/routes';
import { pxToRem } from '../../utils/getFontValue';
import { Stack } from '@mui/system';
import { UIColors } from '../../constants/UIConstants';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  InputLabel,
  styled,
  TextField,
  Typography,
} from '@mui/material';

// Custom styled components for Google Sign In button
const GoogleSignInButton = styled(Button)((props) => ({
  borderWidth: pxToRem(3.5),
  borderColor: UIColors.primary.navyBlue,
  color: UIColors.primary.navyBlue,
  minHeight: pxToRem(44),
  fontWeight: 'bold',
  fontSize: pxToRem(18),
  '&:hover': {
    borderWidth: pxToRem(3.5),
    borderColor: UIColors.primary.navyBlue,
    color: UIColors.primary.navyBlue,
    cursor: 'pointer',
  },
  '&.Mui-disabled': {
    color: UIColors.primary.navyBlue,
    borderWidth: pxToRem(3.5),
    borderColor: UIColors.primary.navyBlue,
  },
}));
const StyledGoogleBox = styled(Box)((props) => ({
  height: pxToRem(36),
  width: pxToRem(36),
}));
const GoogleLoader = styled(CircularProgress)((props) => ({
  marginTop: pxToRem(6),
  marginBottom: pxToRem(6),
  color: UIColors.primary.navyBlue,
}));
// Styles
const loginPageStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  padding: pxToRem(21),
};
const loginFormStyle = {
  maxWidth: pxToRem(387),
  width: '100%',
  height: pxToRem(432),
};
const dividerStyle = {
  ':before, :after': {
    borderColor: UIColors.primary.darkRed,
    borderWidth: pxToRem(4),
  },
};
const forgotPasswordStyle = {
  color: UIColors.secondary.brightBlue,
  '&:hover': {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
};
const loginButtonStyle = {
  backgroundColor: UIColors.primary.navyBlue,
  '&:hover': {
    backgroundColor: UIColors.primary.navyBlue,
    boxShadow: 10,
  },
};

function Login() {
  const intl = useIntl();
  const navigate = useNavigate();
  const [displayGoogleLoader, setDisplayGoogleLoader] = useState(false);

  // Google SSO
  const handleGoogleSignIn = async () => {
    setDisplayGoogleLoader(true);
    await googleSignIn();
  };
  // Check whether user is logged in
  const checkSession = useCallback(async () => {
    if (await isCognitoSessionActive()) {
      navigate(pageRoutes.dashboard);
    }
  }, [navigate]);

  useEffect(() => {
    checkSession();
  }, [checkSession]);

  return (
    <Box sx={loginPageStyle}>
      <Stack sx={loginFormStyle} spacing={3.25}>
        <GoogleSignInButton
          variant="outlined"
          onClick={handleGoogleSignIn}
          endIcon={
            <StyledGoogleBox>
              {!displayGoogleLoader ? (
                <img
                  src={googleLogo}
                  height="100%"
                  width="100%"
                  alt="google-logo"
                />
              ) : (
                <GoogleLoader size={pxToRem(24)} thickness={2.75} />
              )}
            </StyledGoogleBox>
          }
          disabled={displayGoogleLoader}
        >
          <FormattedMessage
            id={loginPage.googleSignInButton.id}
            defaultMessage={loginPage.googleSignInButton.default}
            values={{ signInOption: 'GOOGLE' }}
          />
        </GoogleSignInButton>
        <Box>
          <Divider sx={dividerStyle} textAlign="center">
            <Typography fontWeight="bolder">
              <FormattedMessage
                id={loginPage.divider.id}
                defaultMessage={loginPage.divider.default}
              />
            </Typography>
          </Divider>
        </Box>
        <Stack spacing={0.5}>
          <InputLabel>
            <Typography
              color={UIColors.textColors.grey90}
              fontSize={16}
              variant="body2"
              fontWeight="bolder"
            >
              <FormattedMessage
                id={loginPage.email.id}
                defaultMessage={loginPage.email.default}
              />
            </Typography>
          </InputLabel>
          <TextField
            size="small"
            fullWidth
            placeholder={intl.formatMessage({
              id: loginPage.usernamePlaceholder.id,
              defaultMessage: loginPage.usernamePlaceholder.default,
            })}
            InputProps={{
              sx: { '& fieldset': { borderColor: UIColors.OnBg.grey80 } },
            }}
            inputProps={{
              style: { color: UIColors.textColors.grey90 },
            }}
          />
        </Stack>
        <Stack spacing={0.5}>
          <InputLabel>
            <Typography
              color={UIColors.textColors.grey90}
              fontSize={16}
              variant="body2"
              fontWeight="bolder"
            >
              <FormattedMessage
                id={loginPage.password.id}
                defaultMessage={loginPage.password.default}
              />
            </Typography>
          </InputLabel>
          <TextField
            type="password"
            size="small"
            placeholder={intl.formatMessage({
              id: loginPage.passwordPlaceholder.id,
              defaultMessage: loginPage.passwordPlaceholder.default,
            })}
            fullWidth
            InputProps={{
              sx: { '& fieldset': { borderColor: UIColors.OnBg.grey80 } },
            }}
          />
        </Stack>
        <Stack alignItems="flex-end">
          <Typography sx={forgotPasswordStyle}>
            <FormattedMessage
              id={loginPage.forgotPassword.id}
              defaultMessage={loginPage.forgotPassword.default}
            />
          </Typography>
        </Stack>
        <Stack>
          <Button sx={loginButtonStyle} variant="contained">
            <FormattedMessage
              id={loginPage.loginButton.id}
              defaultMessage={loginPage.loginButton.default}
            />
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
}

export default Login;
