import AppTheme from './lib/theme';
import Dashboard from './pages/dashboard/Dashboard';
import Footer from './components/footer/Footer';
import Header from './components/header/Header';
import Login from './pages/login/Login';
import PageNotFound from './components/pageNotFound/PageNotFound';
import PrivateRoute from './utils/route/PrivateRoute';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { pageRoutes } from './constants/routes';
import SnackBar from './components/snackbar/SnackBar';

const rootStyle = {
  display: 'grid',
  gridTemplateRows: 'auto 1fr auto',
  gridTemplateColumns: '1fr',
  minHeight: '100vh',
};

function App() {
  return (
    <AppTheme>
      <div className="rpt" style={rootStyle}>
        <BrowserRouter>
          <Header />
          <div className="app-body">
            <Routes>
              <Route path={pageRoutes.rootPath} element={<Login />} />
              <Route
                path={pageRoutes.dashboard}
                element={
                  <PrivateRoute>
                    <Dashboard />
                  </PrivateRoute>
                }
              />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </div>
          <Footer />
          <SnackBar />
        </BrowserRouter>
      </div>
    </AppTheme>
  );
}

export default App;
