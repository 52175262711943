import { BaseTitleTypography } from '../Base';
import { fontWeight } from '../../../../lib/theme/typography';
import { pxToRem } from '../../../../utils/getFontValue';
import { styled } from '@mui/material/styles';
import { TypographyProps } from '@mui/material/Typography/Typography';

type VX = 'medium';

interface IProps extends TypographyProps {
  vx: VX;
}

export const SubTitle = styled(BaseTitleTypography)<IProps>(() => ({
  textTransform: 'none',
  fontSize: pxToRem(16),
  fontWeight: fontWeight.bold,
}));
