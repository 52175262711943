import {
  Box,
  Container,
  ContainerProps,
  Grid,
  Typography,
} from '@mui/material';
import { pageRoutes } from '../../constants/routes';
import { pxToRem } from '../../utils/getFontValue';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

const StyledBox = styled(Box)`
  width: 100%;
  height: auto;
  background-color: ${({ theme }) => theme.palette.primary.main};
`;

interface StyledContainerProps extends ContainerProps {
  hasMaxWidthLimit?: boolean;
}

export const StyledContainer = styled(Container, {
  shouldForwardProp: (prop) => prop !== 'hasMaxWidthLimit',
})<StyledContainerProps>`
  @media (min-width: ${({ theme }) => theme.breakpoints.values.lg}px) {
    padding: ${({ hasMaxWidthLimit }) =>
      hasMaxWidthLimit ? `0 ${pxToRem(24)}` : `0 ${pxToRem(46)}`};
    max-width: ${({ hasMaxWidthLimit, theme }) =>
      hasMaxWidthLimit ? `${theme.breakpoints.values.lg + 30}px` : 'unset'};
  }
`;

const StyledGrid = styled(Grid)`
  flex-direction: column-reverse;
  justify-content: space-around;
  align-items: center;
  min-height: 6.25rem;
  @media (min-width: ${(props) => props.theme.breakpoints.values.lg}px) {
    flex-direction: row;
  }
`;

const CopyrightTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.contrastText};
  font-size: 1rem;
  line-height: 1.5rem;
  font-family: 'Mulish';
  letter-spacing: 0.009rem;
  @media (min-width: ${(props) => props.theme.breakpoints.values.lg}px) {
    font-size: 1.125rem;
  }
`;

const LogoGrid = styled(Grid)`
  justify-content: center;
  padding: 0 0 1.563rem 0;
  @media (min-width: ${(props) => props.theme.breakpoints.values.lg}px) {
    justify-content: flex-start;
    padding: 0 0 0 ${pxToRem(8)};
  }
`;

const CopyRightGrid = styled(Grid)`
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 1.563rem 0;
  @media (min-width: ${(props) => props.theme.breakpoints.values.lg}px) {
    align-items: flex-end;
    padding: 0;
  }
`;

function Footer() {
  const [hasMaxWidthLimit, setHasMaxWidthLimit] = useState(true);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === pageRoutes.rootPath) {
      setHasMaxWidthLimit(true);
    } else {
      setHasMaxWidthLimit(false);
    }
  }, [location]);

  return (
    <StyledBox>
      <StyledContainer hasMaxWidthLimit={hasMaxWidthLimit}>
        <StyledGrid container>
          <LogoGrid item container xs={12} lg={6}>
            <img alt="logo" src="/kickdrum_text_logo.svg" />
          </LogoGrid>
          <CopyRightGrid item container xs={12} lg={6}>
            <CopyrightTypography variant="subtitle1">
              © Kickdrum Technology Group LLC.
            </CopyrightTypography>
            <CopyrightTypography variant="subtitle1">
              All rights reserved.
            </CopyrightTypography>
          </CopyRightGrid>
        </StyledGrid>
      </StyledContainer>
    </StyledBox>
  );
}

export default Footer;
