import Box from '@mui/material/Box';
import SubStepTabLabel from './SubStepTabLabel';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { IStep } from '../../../types/steps';
import { pxToRem } from '../../../utils/getFontValue';
import { styled } from '@mui/material';
import { UIColors } from '../../../constants/UIConstants';

interface IProps {
  steps: IStep[];
  activeStep: number | undefined;
}

const SubStepTabs = styled((props: any) => (
  <Tabs
    TabIndicatorProps={{
      children: <span className="MuiTabs-indicatorSpan" />,
    }}
    {...props}
  />
))({
  '.MuiButtonBase-root': {
    padding: '0',
    marginBottom: `${pxToRem(12)}`,
  },
  '.MuiButtonBase-root: last-child': {
    marginBottom: `${pxToRem(0)}`,
  },
  '& .active': {
    backgroundColor: `${UIColors.primary.grey}`,
  },
  '& .MuiTabs-indicator': {
    visibility: 'hidden',
  }
});

function SubSteps({ steps, activeStep }: IProps) {
  return (
    <Box>
      <SubStepTabs orientation="vertical" value={activeStep} className="tabs">
        {steps.map((step, index) => (
          <Tab
            key={step.labelId}
            label={
              <SubStepTabLabel
                id={step.id}
                isActiveTab={activeStep === index}
                index={index}
                labelId={step.labelId}
                descriptionId={step.descriptionId}
                completionStatus={step.completionStatus}
              />
            }
          ></Tab>
        ))}
      </SubStepTabs>
    </Box>
  );
}

export default SubSteps;
