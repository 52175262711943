import { fontWeight } from '../../../../lib/theme/typography';
import { pxToRem } from '../../../../utils/getFontValue';
import { styled, Typography } from '@mui/material';
import { UIColors } from '../../../../constants/UIConstants';

export const SubStepTitle = styled(Typography)`
  text-align: left;
  width: 100%;
  font-size: ${pxToRem(22)};
  line-height: 140%;
  font-weight: ${fontWeight.bold};
  padding: ${pxToRem(10)};
  width: 100%;
  color: ${UIColors.primary.navyBlue};
`;