import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { stepsLayoutConf } from '../../constants/stepConf';
import {
  CompletionStatus,
  IStep,
  IStepLayoutConf,
  IStepsState,
  ValidationStatus,
} from '../../types/steps';

enum FindTypeFlag {
  self = 'self',
  parent = 'parent',
}

interface UpdateIndexPayload {
  id: string;
  index: number;
}

interface UpdateStatusPayload {
  id: string;
  newStatus: ValidationStatus;
}

interface ProceedStepPayload {
  activeMainStepIndex: number;
  activeSubStepIndex: number;
}

// Creates an initial steps state object from the layout configuration
// Returns the root step state which contains main step and sub steps
// and with properties initialized
const getSteps = (layout?: IStepLayoutConf[], prefix: string = '') => {
  const response: IStep[] = [];
  layout?.forEach((e: IStepLayoutConf) => {
    const id = prefix !== '' ? prefix + '.' + e.step : e.step;
    const step: IStep = {
      id,
      labelId: `${id}.label`,
      descriptionId: `${id}.description`,
      completionStatus: CompletionStatus.none,
      validationStatus: ValidationStatus.none,
      steps: getSteps(e?.subSteps, id),
      activeStepIndex: 0,
    };
    response.push(step);
  });
  if (response[0]) {
    response[0].completionStatus = CompletionStatus.inProgress;
  }
  return response;
};

const shouldUpdateValidationOnNext = ['1.b', '2.b']

const initialMainSteps: IStepsState = {
  steps: getSteps(stepsLayoutConf),
  activeStepIndex: 0,
};

// Helper function that returns an array of traversal ids which
// can be used to traverse through and find a step from the Step State.
// If a step id "1.a" is given, returns an array [1, 1.a].
const getStepTraversalIds = (id: string) => {
  const splitIds = id.split('.');
  let idString: string = splitIds[0];
  const result = [idString];
  for (let i = 1; i < splitIds.length; i++) {
    idString += '.' + splitIds[i];
    result.push(idString);
  }
  return result;
};

// Returns the object from the Step State.
// If I give 1.b it can give the object 1.b or 1 based on if find parameter value
// Use case for getting parent is to update the active step index
// Use case for getting the object itself is to update the object state
const findStep = (
  id: string | string[],
  rootState: IStepsState,
  find: FindTypeFlag = FindTypeFlag.self
) => {
  let traversalIds = Array.isArray(id) ? id : getStepTraversalIds(id);
  traversalIds =
    find === 'parent'
      ? traversalIds.splice(0, traversalIds.length - 1)
      : traversalIds;
  let step: IStepsState | IStep | undefined = rootState;
  for (let i = 0; i < traversalIds.length; i++) {
    if (step?.steps) {
      step = step.steps.find((e: any) => e.id === traversalIds[i]);
    } else {
      step = undefined;
    }
  }
  return step as IStep | undefined;
};

// Returns the index of the object in array
const findStepIndex = (id: string, rootState: IStepsState) => {
  const traversalIds = getStepTraversalIds(id);
  let step: IStepsState | IStep | undefined = rootState;
  let stepIndex: number | undefined;
  for (let i = 0; i < traversalIds.length; i++) {
    if (step?.steps) {
      stepIndex = step.steps?.findIndex((e: any) => e.id === traversalIds[i]);
      if (typeof stepIndex === 'number' && stepIndex >= 0) {
        step = step.steps[stepIndex];
      } else {
        stepIndex = undefined;
        break;
      }
    }
  }
  return stepIndex;
};

export const stepsSlice = createSlice({
  name: 'steps',
  initialState: initialMainSteps,
  reducers: {
    updateActiveStep: (state, action: PayloadAction<UpdateIndexPayload>) => {
      const parentStep = findStep(
        action.payload.id,
        state,
        FindTypeFlag.parent
      );

      if (parentStep) {
        parentStep.activeStepIndex = action.payload.index;
      }
    },

    updateValidationStatus: (
      state,
      action: PayloadAction<UpdateStatusPayload>
    ) => {
      const step = findStep(action.payload.id, state);
      if (step) {
        step.validationStatus = ValidationStatus.valid;
      }
    },

    // Proceeds to next and marks the current step
    proceedToNextStep: (state, action: PayloadAction<ProceedStepPayload>) => {
      const mainStep = state.steps[action.payload.activeMainStepIndex];
      const activeSubStepIndex = action.payload.activeSubStepIndex;
      if (Array.isArray(mainStep.steps)) {
        mainStep.steps[activeSubStepIndex].completionStatus =
          CompletionStatus.completed;
      }
      if (
        Array.isArray(mainStep.steps) &&
        mainStep.steps?.length - 1 > activeSubStepIndex
      ) {
        mainStep.activeStepIndex += 1;
      } else {
        mainStep.completionStatus = CompletionStatus.completed;
        state.activeStepIndex += 1;
      }

      if (Array.isArray(mainStep.steps)) {
        if (
          shouldUpdateValidationOnNext.includes(
            mainStep.steps[mainStep.activeStepIndex].id
          )
        ) {
          mainStep.steps[mainStep.activeStepIndex].validationStatus =
            ValidationStatus.valid;
        }
      }
    },
  },
});

export const {
  updateActiveStep,
  updateValidationStatus,
  proceedToNextStep,
} = stepsSlice.actions;

export default stepsSlice.reducer;
