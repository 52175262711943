import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import DashboardElements from '../../pages/dashboard/common/DashboardElements';
import React, { useState } from 'react';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { DashboardBaseContent } from '../../constants/intl/dashboard';
import { pxToRem } from '../../utils/getFontValue';
import { styled } from '@mui/material';
import { UIColors } from '../../constants/UIConstants';
import { useIntl } from 'react-intl';

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

interface StyledTabProps {
  label: string;
}

interface StyledTabsProps {
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
}
interface ContentTabProps {
  id: string;
  content: DashboardBaseContent[]
}

const StyledBox = styled(Box)`
  background-color: 'background.paper';
  width: '100%';
  margin-top: ${pxToRem(32)};
  border: 1px solid ${UIColors.neutrals.grey60};
`;

const StyledAppBar = styled(AppBar)`
  box-shadow: none;
`;

const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs
    {...props}
    variant="fullWidth"
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  backgroundColor: `${UIColors.globalBG.grey}`,
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    backgroundColor: 'none',
  },
  '& .MuiButtonBase-root': {
    marginRight: '0',
  },
});

const StyledTabPanel = styled(TabPanel)`
  min-height: ${pxToRem(200)};
  background-color: ${UIColors.globalBG.white};
`;

const StyledTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: 'none',
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(15),
  marginRight: theme.spacing(1),
  color: UIColors.primary.navyBlue,
  '&.Mui-selected': {
    backgroundColor: UIColors.primary.navyBlue,
    color: UIColors.textColors.white,
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'rgba(100, 95, 228, 0.32)',
  },
  '&:not(:last-child)': {
    borderRight: `${pxToRem(2)} solid ${UIColors.OnBg.white}`,
  },
}));

const StyledPanelContainer = styled(Box)`
  padding: ${pxToRem(24)};
`;

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <StyledPanelContainer>{children}</StyledPanelContainer>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

function ContentTabs(props: ContentTabProps) {
  const intl = useIntl();
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <StyledBox>
      <StyledAppBar position="static">
        <StyledTabs
          value={value}
          onChange={handleChange}
          aria-label="full width tabs"
        >
          {props.content?.map((e, i) => (
            <StyledTab
              key={e.key}
              label={intl.formatMessage({
                id: e.id,
                defaultMessage: e.defaultMessage,
              })}
              {...a11yProps(i)}
            />
          ))}
        </StyledTabs>
      </StyledAppBar>
      {props.content?.map((e, i) => (
        <StyledTabPanel key={e.key} value={value} index={i}>
          <DashboardElements
            id={props.id}
            data={e.content as DashboardBaseContent[]}
          ></DashboardElements>
        </StyledTabPanel>
      ))}
    </StyledBox>
  );
}

export default ContentTabs;
