import { Box, CircularProgress } from '@mui/material';

const loaderContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
};

function Loader() {
  return (
    <Box sx={loaderContainerStyle}>
      <CircularProgress />
    </Box>
  );
}

export default Loader;
