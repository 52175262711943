import Box from '@mui/system/Box/Box';
import { styled } from '@mui/material';

export const CenteredFlexBox = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

export const FlexRightContainer = styled(Box)`
  display: flex;
  justify-content: flex-end;
  padding: 1.5rem 0;
`;