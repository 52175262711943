import { Box, Divider, Typography } from '@mui/material';
import { fontWeight } from '../../lib/theme/typography';
import { pxToRem } from '../../utils/getFontValue';
import { styled } from '@mui/material/styles';
import { UIColors } from '../../constants/UIConstants';

export const SectionTitle = styled(Typography)`
  color: ${UIColors.primary.navyBlue};
  font-weight: ${fontWeight.black};
  line-height: 140%;
  text-transform: uppercase;
`;

export const ComingSoonTypography = styled(Typography)`
  color: ${UIColors.primary.navyBlue};
  font-size: ${pxToRem(16)};
  font-weight: ${fontWeight.bold};
`;
