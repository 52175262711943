import { Auth, CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import logger from '../logger';

export const googleSignIn = async () => {
  return await Auth.federatedSignIn({
    provider: CognitoHostedUIIdentityProvider.Google,
  })
    .then((response) => logger('log', response))
    .catch((error) => logger('log', error));
};
export const signOut = async () => {
  return await Auth.signOut()
    .then(() => true)
    .catch(() => false);
};

export const isCognitoSessionActive = async () => {
  return await Auth.currentAuthenticatedUser()
    .then((response) => response?.attributes)
    .catch(() => null);
};
