import Select from '../../../components/atoms/select/Select';
import { Box, styled, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { updateValidationStatus } from '../../../redux/slice/stepsSlice';
import { useAppDispatch } from '../../../redux/store';
import { useEffect, useState } from 'react';
import { StepsBaseProps, ValidationStatus } from '../../../types/steps';
import { DashboardBaseContent } from '../../../constants/intl/dashboard';

interface ProjectSettingsProps extends StepsBaseProps {
  content: DashboardBaseContent[];
}

interface ISettingsForm {
  system: string;
  host: string;
  spec: string;
}

const initialSettings: ISettingsForm = {
  system: '',
  host: '',
  spec: '',
};

const StyledBox = styled(Box)`
  margin: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledContainer = styled(Box)`
  width: fit-content;
`;

export default function ProjectSettings(props: ProjectSettingsProps) {
  // Temp code, will change based on api
  const systemOptions = ['MySQL'];
  const hostOptions = ['AWS'];
  const specOptions = ['t2.micro', 't3.micro'];

  const formatMessageData: {
    [key: string]: DashboardBaseContent;
  } = {};

  props.content.forEach((e) => {
    formatMessageData[e.type as string] = e;
  });

  const intl = useIntl();
  const dispatch = useAppDispatch();

  const [values, setValues] = useState(initialSettings);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;

    // Temperory until api integration and state management done
    initialSettings[name as unknown as 'system' | 'host' | 'spec'] = value;

    setValues({
      ...values,
      [name]: value,
    });
  };

  useEffect(() => {
    if (values.system.length && values.host.length && values.spec.length) {
      dispatch(
        updateValidationStatus({
          id: props.id,
          newStatus: ValidationStatus.valid,
        })
      );
    }
  }, [values, props.id, dispatch]);

  return (
    <StyledContainer>
      <form>
        <StyledBox>
          <Typography mr={4}>
            <FormattedMessage
              id={formatMessageData.system?.id as string}
              defaultMessage={
                formatMessageData.system?.defaultMessage as string
              }
            />
          </Typography>
          <Select
            name="system"
            onChange={handleInputChange}
            value={values.system}
            options={systemOptions}
            em={intl.formatMessage({
              id: formatMessageData.selectInputDefault?.id as string,
              defaultMessage: formatMessageData.selectInputDefault
                ?.defaultMessage as string,
            })}
          ></Select>
        </StyledBox>
        <StyledBox>
          <Typography mr={4}>
            <FormattedMessage
              id={formatMessageData.host?.id as string}
              defaultMessage={formatMessageData.host?.defaultMessage as string}
            />
          </Typography>
          <Select
            name="host"
            onChange={handleInputChange}
            value={values.host}
            options={hostOptions}
            em={intl.formatMessage({
              id: formatMessageData.selectInputDefault?.id as string,
              defaultMessage: formatMessageData.selectInputDefault
                ?.defaultMessage as string,
            })}
          ></Select>
        </StyledBox>
        <StyledBox>
          <Typography mr={4}>
            <FormattedMessage
              id={formatMessageData.spec?.id as string}
              defaultMessage={formatMessageData.spec?.defaultMessage as string}
            />
          </Typography>
          <Select
            name="spec"
            onChange={handleInputChange}
            value={values.spec}
            options={specOptions}
            em={intl.formatMessage({
              id: formatMessageData.selectInputDefault?.id as string,
              defaultMessage: formatMessageData.selectInputDefault
                ?.defaultMessage as string,
            })}
          ></Select>
        </StyledBox>
      </form>
    </StyledContainer>
  );
}
