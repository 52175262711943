import { fontWeight } from '../../../lib/theme/typography';
import { styled, Typography } from '@mui/material';
import { UIColors } from '../../../constants/UIConstants';

export const BaseTitleTypography = styled(Typography)({
  color: UIColors.primary.navyBlue,
  fontWeight: fontWeight.heavy,
  textTransform: 'uppercase',
});

export const BaseBodyTypography = styled(Typography)({
  color: UIColors.textColors.black,
  fontWeight: fontWeight.heavy,
});