import * as Sentry from '@sentry/react';
import { sentryInitConfig } from '../../config/sentry';
import {
  CustomContextType,
  TagsType,
  UserDetailsType,
} from '../../types/sentry';
import {
  environment,
  isSentryEnabled,
  projectName,
  projectVersion,
  sentryDsn,
} from '../../config/env';

const sentry = {
  init(userDetails?: UserDetailsType) {
    if(!isSentryEnabled) {
      return
    }
    Sentry.init({
      dsn: sentryDsn,
      debug: sentryInitConfig.debug,
      maxBreadcrumbs: sentryInitConfig.maxBreadcrumbs,
      environment: environment,
      release: `${projectName}@${projectVersion}`,
      beforeSend(event) {
        // This is the place where you add conditional check for errors to get logged to sentry server or not
        // if this call back returns null, the error will not be logged in sentry.
        // this can be used to throttle the number of errors caught in recursive blocks of code.
        // const error = hint.originalException;

        // if (
        //   error &&
        //   error.message &&
        //   (error.message.match(/Websocket disconnected!/i) ||
        //     error.message.match(/Extension context invalidated/i))
        // ) {
        //   return null;
        // }
        return event;
      },
    });

    if (userDetails) {
      this.setUser(userDetails);
    }
  },

  setUser(userDetails: UserDetailsType) {
    Sentry.setUser(userDetails);
  },

  capture(
    errorTitle: string,
    tags: TagsType = {},
    level: Sentry.SeverityLevel = 'error',
    customContext: CustomContextType = {}
  ) {
    const scope = new Sentry.Scope();

    scope.setTags(tags);
    scope.setLevel(level);

    for (let [contextTitle, context] of Object.entries(customContext)) {
      scope.setContext(contextTitle, context);
    }

    Sentry.captureMessage(errorTitle, scope);
  },
};

export default sentry;
